import { addLocaleData } from 'react-intl';

import 'moment/locale/el';
import antdLocale from 'antd/lib/locale-provider/el_GR';
import intlLocale from 'react-intl/locale-data/el';
import messages from './messages.po';

addLocaleData(intlLocale);

export default { antdLocale, intlLocale, messages };
